.MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 {
  position: relative;
}

.MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 .background-image {
  background-image: url(
https://mp1md-pub.s3-us-west-2.amazonaws.com/content/4-hzNpCxOBurcRkK_regular.jpeg);
  background-size: cover;
  background-position: 50%!important;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

@media (min-width: 900px) {
  .MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 {
  }
  .MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 .background-image {
    background-position: center;
  }
}
.MarketingCards8a31c08b35f44c5c8829Fd50642deafd {
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  max-width: 1600px;
  margin: 0 auto;
  padding: 4rem 2rem;
  font-family: Inter;
}

.MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}

.MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .CardHeading {
  font-size: 1.75rem;
  font-weight: 700;
}

.MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .CardTextContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .CardTextContent > * {
  border-left: 0.3125rem solid var(--accent-color-2a-50);
  padding-left: 1.25rem;
}

.MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .CardTextContent .CardText {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}

.MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .ButtonWrapper {
  padding-top: 0.5rem;
}

.MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .ImageWrapper {
  display: flex;
  justify-content: center;
}

.MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .ImageWrapper img {
  border-radius: 0.625rem;
  width: 100%;
  max-width: 500px;
  max-width: 500px;
  object-fit: cover;
}

@media (min-width: 900px) {
  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd {
      padding: 2rem 6rem;
  }

  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard {
    gap: 4rem;
  }

  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard > * {
    flex-grow: 1;
    width: 50%;
  }

  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard.even {
    flex-direction: row-reverse;
  }

  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard.odd {
    flex-direction: row;
  }
  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .ImageWrapper {
    display: flex;
    align-items: center;
  }

  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard.even .ImageWrapper {
    justify-content: end;
  }

  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard.odd .ImageWrapper {
    justify-content: start;
  }

  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .ImageWrapper img {
    max-width: 400px;
    max-height: 400px;
  }
}

@media (min-width: 1200px) {
  .MarketingCards8a31c08b35f44c5c8829Fd50642deafd .MarketingCard .ImageWrapper img {
    max-width: 500px;
    max-height: 500px;
  }
}
.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 5rem;
  position: relative;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .header-top-opacity {
  position: absolute;
  height: 100%;
  inset: 0;
  background-color: var(--accent-color-1a-90);
  opacity: 1;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  inset: 0;
  height: 100%;
  padding: 1rem;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .linked-logo {
  display: flex;
  justify-content: start;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .logo {
  width: 8rem;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation {
  display: none;
  flex-grow: 1;
  gap: 0;
  font-family: "Bebas Neue";
  font-size: 1rem;
  letter-spacing: 0.05rem;
  justify-content: end;
  align-items: center;
  color: white;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link {
  font-weight: 600;
  padding: 0.325rem 1rem;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link[href="/join"],
.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link[href="/login"],
.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link[href="/members/home"] {
  padding: 0.325rem 2rem;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link[href="/login"],
.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link[href="/members/home"] {
  padding-left: 4rem;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link[href="/join"] {
  background: var(--accent-color-1a-50);
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link[href="/join"]:hover {
  background: var(--accent-color-1a-40);
  color: white;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation .Link:hover {
  color: var(--secondary-color-100);
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .NavigationMenuButton {
color: var(--secondary-color-100);
  opacity: 1;
  display: flex;
}

.DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */

@media (min-width: 900px) {
  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 {
  }

  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .header-inner {
        padding: 1rem 2rem;
  }

  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .linked-logo {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    min-width: 8rem;
  }
  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .logo {
  }

  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .navigation {
    flex-grow: 4;
  }

  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation {
    display: flex;
    font-size: 1.25rem;
  }

  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .Navigation {
    font-size: 1.5rem;
  }
  .DefaultHeaderTop51825e224cf843438afd166ba0ffe905 .logo {
  }
}
.Hero7c64c36eD94841f99c1355c14a5b553f {
  position: relative;
  /*background: linear-gradient(rgba(216, 53, 139, 0.31), rgba(216, 53, 139, 0.31)), url('https://mp1md-pub.s3.amazonaws.com/orgs/spsea/skyline2.jpeg') center/cover no-repeat;*/
  background: linear-gradient(#3e7fbf22, #1d3b5944), url(https://mp1md-pub.s3.amazonaws.com/orgs/spsea/skyline2.jpeg) center / cover no-repeat;
  background-position: center 0;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .opacity {
  position: absolute;
  inset: 0;
  background-color: rgb(0, 0, 0, 0.2);
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-main-container {
  height: 40rem;
  display: flex;
  position: relative;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-inner-content-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-content-container {
  width: 55%;
  padding: 8rem 0rem 8rem 5rem;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-image-container {
  width: 45%;
  margin-left: 0rem;
  position: relative;
  margin-top: 14rem;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-content-container .title {
  color: #FFF;
  text-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 0.0625rem;
  -webkit-text-stroke-color: #000;
  font-family: "Bebas Neue";
  font-size: 7.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5.9375rem;
  letter-spacing: -0.15rem;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-content-container .body {
  color: #FFF;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.375rem;
  margin-bottom: 2.5rem;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-content-container button {
  background: var(--accent-color-1a-50)
  color: white;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 1rem 3.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border: none;
  color: #FFF;
  font-family: Inter;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-content-container button:hover {
  background-color: #31A8C2;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-image-inner-container {
background-color: var(--secondary-color-100);
  width: 16.53625rem;
  height: 16.46875rem;
  flex-shrink: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 10rem;
}

.Hero7c64c36eD94841f99c1355c14a5b553f .hero-image--blue-container {
  width: 16.53625rem;
  height: 16.46875rem;
  flex-shrink: 0;
background: var(--accent-color-1a-50);
  position: absolute;
  top: 10px;
  left: -10px;
  margin-left: 10rem;
}

@media only screen and (max-width: 900px) {
  .Hero7c64c36eD94841f99c1355c14a5b553f .hero-content-container {
    width: 100%;
    padding: 0;
  }

  .Hero7c64c36eD94841f99c1355c14a5b553f .hero-image-container {
    width: 100%;
    padding: 0;
    margin-top: 5rem;
    margin-left: 0rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    height: 200px;
  }

  .Hero7c64c36eD94841f99c1355c14a5b553f .hero-inner-content-container {
    display: inline;
  }

  .Hero7c64c36eD94841f99c1355c14a5b553f .hero-main-container {
    height: auto;
    width: 100%;
    padding: 5rem 2rem 10rem;
  }

  .Hero7c64c36eD94841f99c1355c14a5b553f .hero-content-container h1 {
    font-size: 6.25rem;
  }

  .Hero7c64c36eD94841f99c1355c14a5b553f .hero-image-inner-container {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin-left: 0rem;
  }

  .Hero7c64c36eD94841f99c1355c14a5b553f .hero-image--blue-container {
    top: 10px;
    left: calc(50% + -10px);
    transform: translateX(-50%);
    margin-left: 0rem;
  }

  .Hero7c64c36eD94841f99c1355c14a5b553f .hero-content-container button {
    width: 100%;
  }
}
.MarketingBodyE226638cA06748b6971867905336a3a6 {
  margin-bottom: 0rem;
}
.PageQuote647c7dff74474c349620876fd9363ec4 {
  display: flex;
  flex-wrap: wrap;
}
.ShortHeader55b21936607f4a9e81767e09718c8760 {
  background-color: white;
  color: #030C22;
  display: flex;
  justify-content: space-between;
  height: 5rem;
  position: relative;
}

.ShortHeader55b21936607f4a9e81767e09718c8760 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/spsea/seattle-skyline-final.jpeg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.ShortHeader55b21936607f4a9e81767e09718c8760 .header-top {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
}

.ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .logo {
  width: 7rem;
}

.ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeader55b21936607f4a9e81767e09718c8760 {
    height: 7rem;
  }

  .ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .logo {
  }

  .ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .Navigation {
    display: flex;
  }

  .ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader55b21936607f4a9e81767e09718c8760 .header-top {
    padding: 0 1.75rem;
  }

  .ShortHeader55b21936607f4a9e81767e09718c8760 .header-top .logo {
    width: 10rem;
  }
}
.SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 {
  min-height: 19rem;
  position: relative;
  background-color: var(--accent-color-1a-90);
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: clip;
}

.SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 .background-color {
  background-color: var(--accent-color-1a-90);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  z-index: -1;
}

.SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 .background-image {
  display: none;
}

.SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 .header-top-opacity {
  display: none;
}

.SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 .header-top {
  z-index: 1;
  position: relative;
}

@media (min-height: 900px) {
  .SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 {
    min-height: 24rem;
  }
  .SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 .background-image {
    height: 100%;
  }
}
.HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 {
  color: #030C22;
  min-height: 42rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 4rem;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  margin-top: 1.8rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 .body {
  letter-spacing: 1.8px;
  line-height: 1.2em;
  margin-bottom: 1rem;
  max-width: 45rem;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: Montserrat;
}

.HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 .button {
  text-align: center;
}

.HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  background-color: #eb0a8d;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 .title {
  }

  .HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 .button {
  }
}
.MessagePages838d4f9d4d054fc09d11Fe830f9ad464 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.SecondaryNav19e547e0Dcc34cd69396027138fc29a1 {
  padding: 1rem 0;
}
.HomeHeader6ccf55d960d849f29ba2F1da3bfae29d {
  position: relative;
  color: #030C22;
  overflow: clip;
}

@media (min-width: 900px) {
  .HomeHeader6ccf55d960d849f29ba2F1da3bfae29d {
  }
  .HomeHeader6ccf55d960d849f29ba2F1da3bfae29d .background-image {
    background-position: center;
    height: 100%;
  }
}
.AboutUsPage8928a42a866f4f4b9a19866be6ebb85b > main {
  background-color: var(--accent-color-1a-90);
  color: white;
}
.SecondaryMarketingHero13705060C59b4611801988461c9a3b2e {
  color: #fff;
  flex-grow: 1;
  text-align: center;
  position: relative;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/spsea/skyline2.jpeg);
  background-position: 0 39%;
  background-repeat: no-repeat;
  background-size: cover;
}

.SecondaryMarketingHero13705060C59b4611801988461c9a3b2e .background-opacity {
  position: absolute;
  inset: 0;
  background-color: #1d3b5944;
}

.SecondaryMarketingHero13705060C59b4611801988461c9a3b2e .title {
  margin-top: 1rem;
  position: relative;
  font-family: 'Bebas Neue';
  font-size: 4rem;
  font-weight: 500;
  line-height: inherit;
  letter-spacing: 0.1rem;
  text-shadow: 0 .25rem .25rem rgba(0, 0, 0, .25);
}

@media (min-width: 640px) {
  .SecondaryMarketingHero13705060C59b4611801988461c9a3b2e {
    padding-left: 5rem;
  }

  .SecondaryMarketingHero13705060C59b4611801988461c9a3b2e .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHero13705060C59b4611801988461c9a3b2e .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHero13705060C59b4611801988461c9a3b2e .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .marketing-card-main-container {
  background-color: #11202F;
  padding: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  width: 100%;
  margin: auto;
  grid-template-areas:
    "who-we-are who-we-are-image"
    "sex-positivity-image what-is-sex-positivity";
  box-sizing: border-box;
}

.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .who-we-are-element {
  grid-area: who-we-are;
  padding: 1.25rem;
  background-color: #11202F;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 3.125rem;
}

.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .inner-border-container-element {
  border-left: 0.3125rem solid #E8018F;
  padding-left: 1.25rem;
}

.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .who-we-are-image-element {
  grid-area: who-we-are-image;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.125rem;
}

.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .what-is-sex-positivity-element {
  grid-area: what-is-sex-positivity;
  padding: 1.25rem;
  background-color: #11202F;
  text-align: left;
  display: flex;
  align-items: center;
}

.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .sex-positivity-image-element {
  grid-area: sex-positivity-image;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .who-we-are-heading-element,
.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .what-is-sex-positivity-heading-element {
  color: #FFF;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
  margin-bottom: 2rem;
}

.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .who-we-are-paragraph-element,
.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .what-is-sex-positivity-paragraph-element {
  color: #FFF;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}

.MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .image-element {
  max-width: 100%;
  height: 36rem;
  border-radius: 0.625rem;
}

@media (max-width: 768px) {
  .MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .marketing-card-main-container {
    grid-template-columns: 1fr;
    grid-template-areas:
      "who-we-are"
      "who-we-are-image"
      "what-is-sex-positivity"
      "sex-positivity-image";
    padding: 5rem 1rem;
  }

  .MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .who-we-are-element,
  .MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .what-is-sex-positivity-element {
    border-left: none;
    border-top: 0.3125rem solid #E8018F;
  }

  .MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .sex-positivity-image-element {
    order: 4;
  }

  .MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .what-is-sex-positivity-element {
    order: 3;
  }

  .MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .inner-border-container-element {
    border: none;
  }

  .MarketingCards2D57bd2f76a0843cb872f1c4155897f22  .image-element {
    max-width: 100%;
    height: 25rem;
    border-radius: 0.625rem;
  }
}
.LevelingPage3ee6b6ff6bb54ee08288Df3c39e9b4ec > main {
  background-color: var(--accent-color-1a-90);
  color: white;
}
.HowWereDifferentEa86b6bd46e744d8A12c74d046f97c17 > main {
  background-color: var(--accent-color-1a-90);
  color: white;
}
.WelcomeBackPopUp {
  padding: 2rem;
  position: relative;
  background-color: white;
  box-shadow: 5px 5px 5px rgb(50 50 50 / 20%);
  color: white;
}

.WelcomeBackPopUp .CloseButton {
  color: black;
}

.WelcomeBackPopUp .BackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/spsea/skyline2.jpeg);
  opacity: 0.5;
  background-position: center;
  background-size: cover;
}

.WelcomeBackPopUp .Body {
  position: relative;
}

.WelcomeBackPopUp .BackgroundOpacity {
  /*background-color: #884b6b99;*/
  background-color: #1d3b5944;
  position: absolute;
  inset: 0;
}

.WelcomeBackPopUp .Button {
  background-color: black;
  color: white;
  font-family: 'Montserrat';
  font-size: 120%;
  letter-spacing: 0.05rem;
  padding: 0.5rem 2rem;
  text-align: center;
  box-shadow: 3px 3px 3px #32323233;
}

.gf-customer .SideCar {
  padding: 2rem;
}

.gf-customer .SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: 'Bebas Neue';
  font-size: 1.25rem;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
}

.gf-customer .SideCar .Navigation .Link {
  color: #000;
  display: block;
}

.gf-customer .SideCar .Navigation .Link:hover,
.gf-customer .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.HomePageAe61bb6676ff4bc0B32cC5616fc39386 .PageQuote {
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 100%;
  justify-content: stretch;
}

.HomePageAe61bb6676ff4bc0B32cC5616fc39386 .PageQuote .image-col {
}

.HomePageAe61bb6676ff4bc0B32cC5616fc39386 .PageQuote .image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.HomePageAe61bb6676ff4bc0B32cC5616fc39386 .PageQuote .TextContent {
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  font-family: "Bebas Neue";
  font-size: 3rem;
  letter-spacing: 0.1rem;
  line-height: 4rem;
  position: relative;
  padding: 3rem 2rem;
  text-align: center;
}

@media (min-width: 900px) {
  .HomePageAe61bb6676ff4bc0B32cC5616fc39386 .PageQuote {
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
  }
  .HomePageAe61bb6676ff4bc0B32cC5616fc39386 .PageQuote .image-col {
  }
  .HomePageAe61bb6676ff4bc0B32cC5616fc39386 .PageQuote .TextContent {
    font-size: 4.25rem;
    padding: 4rem;
  }
}
.DefaultFooterC2a6801c196044a0800366447a0aac44 {
  background: var(--accent-color-1a-90);
  color: white;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem 2rem;
  font-family: Montserrat;
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 > * {
  text-align: center;
}

.gf-customer .DefaultFooterC2a6801c196044a0800366447a0aac44 a {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 0rem;
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 .LogoWrapper {
  font-size: 90%;
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 .LogoWrapper {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 .GroupCopyright {
  font-size: 90%;
  color: white;
}

.footer-donate-button {
  display: flex;
  padding: 0.5rem 3rem;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: var(--accent-color-1a-50);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.8rem;
  border: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer-donate-button:hover {
  background-color: var(--accent-color-1a-40);
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 .GroupFlowTag:hover {
  color: #00c298;
}

.DefaultFooterC2a6801c196044a0800366447a0aac44 .GroupFlowTag .PoweredBy {
  font-size: 1rem;
}